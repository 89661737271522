<!--Start Back To Top Button--> 
	<a href="#" (click)="$event.preventDefault()" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
<!--End Back To Top Button-->
<!--footer -->
<footer class="page-footer">
	<div class="ms-auto">
		{{'powered' |translate}}
		<a href="https://makseb.fr/makseb/" target="_blank">
		  <span> Makseb Solutions</span>
		</a>
	  </div>
</footer>
<!-- end footer -->
