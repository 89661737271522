import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiServices } from './services/api';
import { Howl } from 'howler';
import { ToastService } from './toast-service';
import { io, Socket } from 'socket.io-client';
import { WebsocketService } from './services/websocket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  eventSubscription: Subscription;
  errorSubscription: Subscription;
  message: string = '';
  ordermassage: string = '';
  sound: Howl;
  commande: Boolean
  dataorder: any = []
  deliveryuber: any;
  socket: Socket;
  automaticuber: any;
  constructor(
    private translate: TranslateService,
    private authService: ApiServices,
    private ngZone: NgZone,
    private toastService: ToastService, private socketService: WebsocketService
  ) {
    this.socket = null;

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('fr');
    this.sound = new Howl({
      src: ['assets/Ring.mp3'],
      volume: 1.0,
      loop: false,
    });
  }
  Automatic: any
  ngOnInit(): void {
    this.getstorebyid();
    //connect to sse
    const tokenSSE = localStorage.getItem('token');
    // Set default language
    const defaultLang = localStorage.getItem('defaultLang');
    if (defaultLang && this.translate.getLangs().includes(defaultLang)) {
      this.translate.setDefaultLang(defaultLang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }
  iduser: any
  uberdelevery(id: string) {
    this.authService.Creerdevis(id).subscribe(
      (response) => {
        console.log("uber", response)
      },
      (error) => {
        console.error('Erreur lors de la création du devis :', error);
      }
    );
  }
  changeLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('defaultLang', lang);
  }
  private getstorebyid(): void {
    this.authService.getStroreById(this.authService.getStore()).subscribe(
      (data) => {
        this.commande = data.managingacceptedorders.Automatic
        this.deliveryuber = data.modeUberdirect;
      },
      error => {
        console.error('Error fetching store details:', error);
      }
    );
  }
  // Method to fetch orders by number
  getorderbynumber() {
    this.authService.getallorderbystore(this.authService.getStore()).subscribe(
      (response) => {
        this.dataorder = response;
      }, error => { console.error('Error fetching orders', error); }
    );
  }
  getAllorder() {
    this.authService.getOrderByStoreId(this.authService.getStore()).subscribe(
      (response) => {
        this.authService.orders = response;
        if (this.commande == true) {
          if (this.deliveryuber === true) {
          }
        }
      },
      error => { });
  }
  // Placeholder method to show success/toast message
  private showSuccess(message: string): void {
    this.toastService.show(message, { delay: 10000 });
  }
  private showDanger(message: string): void {
    this.toastService.show(message, { classname: 'bg-danger text-light', delay: 15000 });
  }
}