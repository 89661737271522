<router-outlet>
  <!-- <df-messenger
    intent="WELCOME"
    chat-title="MaksebBot"
    agent-id="61f11423-5734-49fb-b029-0f7e7051d34e"
    language-code="fr"
    
  ></df-messenger> -->
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</router-outlet>
